import { Card, CardBody, Form } from 'reactstrap'
import { Formik } from 'formik'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  CheckInput,
  CountryInput,
  SelectInput,
  SubmitButton,
  TextInput
} from './Inputs/inputs'
import * as yup from 'yup'
import { useEffect, useState } from 'react'
import { SuccessMessage } from './General/SuccessMessage'
import { POST } from '../_helpers/apiHelper'
import { connect } from 'react-redux'
import { getUtmSource } from '../_helpers/common'

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const ReferSchema = yup.object().shape({
  companyLocality: yup.string().notOneOf(['NONE'], 'THIS_FIELD_IS_REQUIRED'),
  companySize: yup.string().notOneOf(['NONE'], 'THIS_FIELD_IS_REQUIRED'),
  firstName: yup.string().required('THIS_FIELD_IS_REQUIRED'),
  lastName: yup.string().required('THIS_FIELD_IS_REQUIRED'),
  emails: yup.string().email().required('THIS_FIELD_IS_REQUIRED'),
  companyName: yup.string().required('THIS_FIELD_IS_REQUIRED')
})

const b2bSuccessMessages = [
  'THANK_YOU_FOR_SUBMITTING',
  'YOUR_COMPANY_CLOSER_TO_BE_MEDITOPIAN'
]

const mapToState = (state) => ({ locale: state.i18n.locale })

const B2bReferralForm = connect(mapToState)(
  injectIntl(({ intl, locale, onComplete, template, consent }) => {
    const [completed, setCompleted] = useState(false)

    return (
      <div
        className={`col-12 col-md-6 b2b-referral-form flex ${
          completed ? 'b2b-form-completed' : ''
        }`}>
        <p className={'downloadable-content-badge'}>{template.badge}</p>
        <h4 className={'title-over-form'}>{template.title}</h4>
        {template.description.split('\n').map((t) => (
          <p className={'paragraph-over-form'}>{t}</p>
        ))}

        <Card
          style={{ width: '100%' }}
          className={'downloadable-content-sticky'}>
          <CardBody
            style={
              completed
                ? { position: 'relative' }
                : { paddingBottom: consent ? '15px' : '' }
            }>
            {completed ? (
              <div style={{}}>
                <SuccessMessage
                  title={'THANK_YOU'}
                  shortMessages={b2bSuccessMessages}
                />
              </div>
            ) : (
              <Formik
                initialValues={{
                  companyLocality: 'NONE',
                  companySize: 'NONE',
                  companyName: ''
                }}
                validationSchema={ReferSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={async (
                  values,
                  { setSubmitting, setErrors, validate, validateForm }
                ) => {
                  if (values.emails.includes('adroit-accessories.co.uk')) {
                    await new Promise((resolve) => {
                      setTimeout(() => resolve(), 1000)
                    })
                    setCompleted(true)
                    onComplete()
                    return
                  }

                  try {
                    let from = JSON.parse(localStorage.getItem('from'))
                    if (!Array.isArray(from)) {
                      from = []
                    }
                    console.log(values)
                    if (values.phone && values.phone.startsWith('+0')) {
                      return setErrors({
                        phone: 'SHOULD_BE_PHONE_NUMBER'
                      })
                    }
                    const excludedEmailDomains = [
                      'yahoo.com',
                      'gmail.com',
                      'hotmail.com',
                      'live.com',
                      'yandex.com',
                      'outlook.com',
                      'icloud.com',
                      'mail.ru',
                      'live.fr'
                    ]
                    const emailDomain = values.emails.split('@')[1]
                    const isExcludedEmailDomain = excludedEmailDomains.some(
                      (excluededEmail) =>
                        excluededEmail.includes(emailDomain) ||
                        emailDomain.includes(excluededEmail)
                    )
                    if (isExcludedEmailDomain) {
                      return setErrors({
                        emails: 'SHOULD_BE_COMPANY_EMAIL'
                      })
                    }
                    values.emails = values.emails.trim()
                    await POST(
                      '/referral-form/downloadable-content',
                      {
                        ...values,
                        form_title: `Downloadable Content | ${template.title}`,
                        from: getUtmSource() || from.join('\n') + '\n'
                      },
                      {},
                      true
                    )
                    setCompleted(true)
                    localStorage.setItem('from', null)
                    onComplete()
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: 'b2b_content_download',
                      refer_info: {
                        ...values,
                        content_name: template.title,
                        content_type: template.badge,
                        from: getUtmSource() || from.join('\n') + '\n'
                      }
                    })
                  } catch (e) {
                    const { message } = e
                    const errObject = {}
                    message.map((e) => {
                      if (typeof e !== 'string') return
                      errObject[e.split(' ')[0]] =
                        e.charAt(0).toUpperCase() + e.slice(1)
                    })
                    setErrors(errObject)
                  }
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setErrors,
                  isSubmitting
                  /* and other goodies */
                }) => {
                  const handleChangeInterupt = function (e) {
                    const { name, value } = e.target
                    if (
                      name.includes('phone') &&
                      value[0] !== '+' &&
                      value !== ''
                    ) {
                      e.target.value = '+' + value
                    }
                    const errs = JSON.parse(JSON.stringify(errors))
                    delete errs[name]
                    setErrors(errs)
                    handleChange(...arguments)
                  }
                  return (
                    <Form onSubmit={handleSubmit}>
                      <h4>{template.form_title}</h4>
                      <div className={'col-12 col-sm-12 flex'}>
                        <TextInput
                          errors={errors}
                          value={values.firstName}
                          label={'FIRST_NAME'}
                          name={'firstName'}
                          onChange={handleChangeInterupt}
                          lg={6}
                        />
                        <TextInput
                          errors={errors}
                          value={values.lastName}
                          label={'LAST_NAME'}
                          name={'lastName'}
                          onChange={handleChangeInterupt}
                          lg={6}
                        />
                      </div>
                      <TextInput
                        errors={errors}
                        value={values.emails}
                        label={'WORK_EMAIL'}
                        name={'emails'}
                        onChange={handleChangeInterupt}
                      />
                      <TextInput
                        errors={errors}
                        value={values.companyName}
                        label={'COMPANY_NAME'}
                        name={'companyName'}
                        onChange={handleChangeInterupt}
                        lg={12}
                        maxLength={13}
                      />
                      <CountryInput
                        errors={errors}
                        value={values.companyLocality}
                        label={'COMPANY_COUNTRY'}
                        name={'companyLocality'}
                        onChange={handleChangeInterupt}
                      />
                      <SelectInput
                        errors={errors}
                        value={values.companySize}
                        name={'companySize'}
                        label={'COMPANY_SIZE'}
                        onChange={handleChangeInterupt}
                        options={[
                          '1-49',
                          '50-100',
                          '101-500',
                          '501-1000',
                          '1,001-10,000',
                          '10,000+'
                        ]}
                      />

                      <SubmitButton text={template.submit_button_text} />
                    </Form>
                  )
                }}
              </Formik>
            )}
            {consent && (
              <p className={'consent'}>
                *By submitting this form you agree to our{' '}
                <a
                  target={'_blank'}
                  href={'https://meditopia.com/en/privacy-policy/'}>
                  Privacy Notice
                </a>{' '}
                and you're opting in to receive our marketing updates via email.
                You can change your mind and opt-out at any time.
              </p>
            )}
          </CardBody>
        </Card>
      </div>
    )
  })
)

export { B2bReferralForm }
