import image from '../../../_assets/downloadable-content/anadoluefes-logo.png'
import detailImage from '../../../_assets/downloadable-content/anadoluefes-casestudy.png'

export default {
  theme_color: 'rgb(103 79 193)',
  use_second_image: true,
  formless: false,
  badge: 'Case Study',
  title: 'Anadolu Efes Mindfully Brews Mental Health Excellence',
  description:
    "Anadolu Efes is a leading company in the Turkish brewing industry. The competitive nature of the industry often affects its employee's mental well-being, increasing stress and anxiety and diminishing their performance. This case study explores how the company addressed this challenge and supported its workforce's mental health through a partnership with Meditopia.",
  details_title: 'In This Case Study',
  details_list:
    'The previous Anadolu Efes initiatives to holistically support their team’s well-being\nThe challenges their employees faced due to the industry’s demands\nHow Meditopia’s content and programs helped them increase their resilience and performance',
  submit_button_text: 'Download',
  form_title: 'Read the case study for free',
  file: 'AnadoluEfes_CaseStudy.pdf',
  page_title:
    'Brewing Resilience: Anadolu Efes and the Journey to Employee Well-being',
  image: image,
  image_width: 92,
  image_top: 42,
  image_left: 10,
  image_hue_disabled: true,
  detail_image: detailImage,
  url: '/downloadable-content/anadolu-efes-case-study'
}
