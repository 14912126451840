import image from '../../../_assets/downloadable-content/NYmetz.png'
import detailImage from "../../../_assets/downloadable-content/NYMets_Meditopia.png";

export default {
    "formless": false,
    "theme_color": "rgb(247 246 255)",
    "badge":"Case Study",
    "use_second_image": true,
    "title":"A Homerun For the NY Mets Mental Well-Being",
    "description":"In elite sports, three major factors are involved in how a game develops: lots of practice, natural skills, and a strong mindset. The New York Mets had the first two, and through an alliance with Meditopia, the players and staff developed a resilient and positive mindset. Read the case study to discover more about these achievements and to check the NYMets opinions on their improvements with Meditopia.",
    "details_title":"In this case study",
    "details_list":"What the NY Mets mindfulness and coaching program was about\nThe players' achievements through Meditopia\nTheir thoughts on their performance improvements",
    "submit_button_text":"Read Case Study",
    "form_title":"Read the case study now",
    "page_title":"A Homerun For the NY Mets Mental Well-Being",
    "image_width":"55",
    "file": "NYMets_CaseStudy.pdf",
    "image_top":"35",
    "image_left":"23",
    "image":detailImage,
    detail_image: image,
    url: '/downloadable-content/ny-mets'
}