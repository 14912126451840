import image from '../../../_assets/downloadable-content/mets2.png'

export default {
    "badge":"Webinar",
    "theme_color": "rgb(255 89 17 / 37%)",
    "title":"Mental health taboos in professional athletes: The New York Mets and Meditopia improved the professional athletes’ mental well-being",
    "description":"When the weight of a championship and the team’s legacy is on your shoulders, having a strong mindset is a supreme advantage. Join us on this webinar to discover how The New York Mets overcome many mental health taboos in sports and improve their players’ well-being with Meditopia’s coaching and mindfulness resources.",
    "details_title":"In this webinar",
    "details_list":"About the common mental well-being challenges among professional athletes and elite teams\nHow coaching and mindfulness have supported the players and staff’s well-being, performance, and resilience\nHow these major improvements have been measured \nWhat can professional teams and companies do to support their teams’ mental well-being and overall performance",
    "submit_button_text":"Watch",
    "form_title":"Watch the webinar now",
    "page_title":"Mental health taboos in professional athletes: The New York Mets and Meditopia improved the professional athletes’ mental well-being",
    "image_width":100,
    "image_top":0,
    "image_left":0,
    "image":image,
    "redirect_uri": "https://youtu.be/BtoAFpuhfJ0",
    url: '/downloadable-content/mental-health-taboos-in-professional-athletes',
    listButtonText: 'Watch now'
}
