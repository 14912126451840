import image from "../../../_assets/downloadable-content/the-benefits-new.png";

export default {
    "theme_color": "rgb(61 145 201)",
    "badge":"Infographic",
    "title":"The Benefits of Supporting Employee Well-Being",
    "description":"Employees’ mental well-being is the key element to a collaborative, motivated, and productive workplace. This infographic comprises the costs of poor employee well-being and the benefits of supporting employee well-being.",
    "details_title":"In this infographic",
    "details_list":"Research findings indicating the importance of employee well-being, work related risk factors for mental health, and causes of workplace stress\nPrincipals of mental fitness\nThe cost of poor well-being in the workplace\nTips to promote mental well-being in the workplace\nBenefits and adding values of employee wellness programs",
    "submit_button_text":"Download",
    "form_title":"Read the infographic for free",
    "file": "The Benefits of Supporting Employee Well-Being.pdf",
    image: image,
    image_width: 100,
    image_top: 32,
    image_left: 0,
    url: '/downloadable-content/the-benefits-of-employee-wellbeing',
    listButtonText: 'Read now'
}