import { Card, CardBody, CardImg, Navbar, NavbarBrand } from 'reactstrap'
import { connect } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useNavigate } from 'react-router-dom'
import { BackgroundedImagedContainer } from '../../../_components/Containers/BackgroundImagedContainer'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import image from '../../../_assets/downloadable-content/alianz-pages.png'
import contents from './index'
import 'swiper/css'
import 'swiper/css/pagination'
import { getShortDateString } from '../../../_components/webinar'

const RelatedContentElement = ({ content, cols }) => {
  const navigate = useNavigate()
  const {
    badge_text,
    webinar_mode,
    webinar_type,
    webinar_date,
    no_page_mode,
    redirect_uri,
    formless,
    badge,
    listButtonText
  } = content
  return (
    <div
      className={cols ? cols : 'col-12 col-md-12 p-0 md:p-3'}
      style={{ zIndex: 300, marginTop: '17px' }}>
      <Card
        className={
          'related-content-shadow !shadow-none !border-[1px] !border-[#EEEEFD] cursor-pointer related-contents-card !rounded-[16px] h-full'
        }
        onClick={() =>
          no_page_mode ? window.open(redirect_uri) : navigate(content.url)
        }>
        {/*(webinar_date || badge_text) ? <div className={'absolute left-[15px] top-[-15px] z-10 flex'}>
                <div
                    className={'font-extrabold ml-[2px] rounded-[30px] h-[30px] text-[12px] bg-[#500FA2] pt-[5px] px-4 text-white uppercase'}>
                    {webinar_date ?
                        (new Date().getTime() > new Date(webinar_date).getTime() ? "WATCH NOW" : getShortDateString(webinar_date)):
                        (typeof badge_text === 'function' ? badge_text(): badge_text)}
                </div>
                    </div>: null*/}
        <div
          style={{ background: content.theme_color }}
          className={
            'pt-[55%] overflow-hidden relative w-[90%] ml-[5%] mt-[5%] rounded-[16px]'
          }>
          <img
            className="absolute top-[5%] left-[5%] w-[90%]"
            src={
              !content.use_second_image ? content.image : content.detail_image
            }
          />
        </div>
        <CardBody className={'p-4 related-content-body'}>
          <h3 className="mb-3 mt-1">
            {webinar_type || content.badge} {badge_text && ` | ${badge_text}`}{' '}
            {webinar_date && ` | ${getShortDateString(webinar_date, true)}`}
          </h3>
          <h2 className={'mb-2 mt-3'}>
            {no_page_mode || content.title.length < 50
              ? content.title
              : content.title.substring(0, 50) + '...'}
          </h2>
          <p className="text-[16px]">
            {no_page_mode || content.description.length < 132
              ? content.description
              : content.description.substring(0, 132) + '...'}
          </p>
          {
            <div className={'w-fit'}>
              <div
                className={
                  'font-extrabold ml-[2px] rounded-[30px] h-[30px] text-[12px] bg-[#834CC9] pt-[5px] px-4 text-white uppercase'
                }>
                {listButtonText ||
                  (webinar_date
                    ? new Date().getTime() > new Date(webinar_date).getTime()
                      ? 'Watch Now'
                      : 'Learn More'
                    : webinar_mode
                    ? 'Watch Now'
                    : formless
                    ? 'Read Now'
                    : badge.toUpperCase() === 'CASE STUDY'
                    ? 'Read Now'
                    : 'Register Now')}
              </div>
            </div>
          }
          {no_page_mode && (
            <button className="text-white bg-[#834CC9] px-5 w-full md:w-fit purple-button">
              Okumak için tıkla
            </button>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

function RelatedContents({ data }) {
  const filteredContents = Object.values(contents).filter(
    (el) => el.title !== data.title
  )

  return (
    <BackgroundedImagedContainer bgColor={'#F7F7FB'} className="pb-5 pt-5">
      <h4 className={'p-3 related-contents-title'}>Related Contents</h4>
      <div className="w-100 flex">
        <Swiper
          slidesPerView={3}
          spaceBetween={20}
          pagination={{
            clickable: true
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 0
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20
            }
          }}
          modules={[Pagination]}
          className="mySwiper pb-3 w-100 h-fit">
          {filteredContents.map((c) => {
            return (
              <SwiperSlide className="mb-5">
                <RelatedContentElement content={c} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </BackgroundedImagedContainer>
  )
}

const mapToState = (state) => {
  return { auth: state.auth }
}

export const RelatedContentsWoSwiper = connect(mapToState)(
  ({ noInlinePadding, disableTitle, data }) => {
    //console.log(contents)
    //console.log(data)
    const filteredContents = Object.values(data) //Object.values(contents).filter((el) => el.title !== data.title)

    return (
      <BackgroundedImagedContainer
        bgColor={'white'}
        className={`pb-5 pt-5 min-h-[100vh] ${noInlinePadding && 'px-0'}`}>
        {!disableTitle && (
          <h4 className={'md:p-3 related-contents-title'}>Resource Library</h4>
        )}
        {!disableTitle && (
          <p className={'related-contents-description'}>
            Browse our resources about transforming mental health. The library
            of case studies, guidebooks, webinars, and more is ready for your
            access.
          </p>
        )}

        <h4 className="md:px-3 text-md font-bold !-mb-4 mt-4">Case Studies</h4>
        <div className="w-100 flex h-fit">
          {filteredContents
            .filter((c) => String(c.badge).toUpperCase() === 'CASE STUDY')
            .map((c) => {
              return (
                <RelatedContentElement
                  content={c}
                  cols={'col-12 col-md-6 col-lg-4 p-3'}
                />
              )
            })}
        </div>
        <h4 className="md:px-3 text-md font-bold !-mb-4 mt-4">
          Other Contents
        </h4>
        <div className="w-100 flex h-fit">
          {filteredContents
            .filter((c) => String(c.badge).toUpperCase() !== 'CASE STUDY')
            .map((c) => {
              return (
                <RelatedContentElement
                  content={c}
                  cols={'col-12 col-md-6 col-lg-4 p-3'}
                />
              )
            })}
        </div>
      </BackgroundedImagedContainer>
    )
  }
)

export default connect(mapToState)(RelatedContents)
