import image from "../../../_assets/downloadable-content/Visual _ B2B - Exness Private Webinar - Catalog Image.png"
//import imageMobile from "../../../_assets/downloadable-content/Mental Health Day - Catalog Mobile copy.png"

export default {
    "badge":"Private Webinar",
    "theme_color": "#b3b4ff9c",
    "title":"Mental health support through private webinars",
    "webinar_about_title":"Mental health support through private webinars",
    "webinar_about2_title":"What you will learn",
    //"webinar_date":"Fri Nov 11 2022 11:59:24 GMT+0300 (GMT+03:00)",
    "description":"Exness is a leader multi-asset brokerage organization and is well aware of the industry’s impact on its team’s well-being. Their goal with our partnership was to increase their team’s resilience. While also supporting their mental health through private webinars focused on topics they truly wanted support on.",
    "details_title":"In This Webinar",
    "details_description":() => <>Meditopia’s private webinars are an enlightening tool aimed at <b>detangling people’s doubts about mindfulness at work</b>, and offering them <b>detailed and professional guidance</b> on topics that are relevant to their personal development and work satisfaction.{'\n\n'}This is what we talked about with Exness’ team:</>,
    "details_list":"Introduction to Mindfulness & Live Meditation Session/ub/ with Deniz Bağan\n" +
        "Staying Mindful While Working Remotely/ub/ with Sevgen Bengi Kıran\n" +
        "Stress, Wellbeing, and Work-Life Balance/ub/ with Dr. Banu Çankaya Şahin\n" +
        "Emotions with a Relocation to Another Country/ub/ with Dr. Banu Çankaya Şahin\n" +
        "Finding Fulfillment in Your Career and Feeling Accomplished:/ub/ More Is Not Better with Sinan Güler - Professional Basketball Player",
    "submit_button_text":"Contact us to learn more",
    "form_title":"",
    "page_title":"Private webinars to enhance the resilience and well-being of a team in the financial industry",
    "image":image,
    "webinar_mobile":image,
    "image_width":40,
    "image_top":16,
    "image_left":30,
    "formless":true,
    "webinar_mode":true,
    "detail_image":"not_visible",
    url: '/downloadable-content/private-webinars-to-enhance-the-resilience',
    "webinar_url": "https://meditopia.com/en/business-bookademo/",
}