import {BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom'
import {routes} from "./routes";
import {Suspense, useEffect} from "react";
import NavBar from "../_components/navBar";
import {connect} from "react-redux";
import {getAccountInfo} from "../_redux/actions/auth/auth.actions";
import {Footer} from "../_components/footer";

export const GetQueryStringParams = (sParam) => {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');

    for (var i = 0; i < sURLVariables.length; i++)
    {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam)
        {
            return sParameterName[1];
        }
    }
}

function saveFrom (path) {
    try {
        let currentFrom = JSON.parse(localStorage.getItem('from'))
        if (!Array.isArray(currentFrom)) {
            currentFrom = []
        }
        const from = GetQueryStringParams('from')
        if (from) {
            currentFrom.push(from)
        }
        currentFrom.push(path)

        currentFrom.reverse()
        currentFrom = currentFrom.filter(function (value, index, array) {
            return currentFrom.indexOf(value) === index
        })
        currentFrom.reverse()
        localStorage.setItem('from', JSON.stringify(currentFrom))
    } catch (e) {
        console.log(e)
    }
}

const CheckViewAuth = connect((state) => {
    return {auth: state.auth}
})(({auth, route}) => {
    saveFrom(route.path)
    const navigate = useNavigate()
    useEffect(() => {
        if((!auth || !auth.token) && route.authRequired) return navigate('/login')

        if((auth && auth.token) && route.category === 'login-register') navigate('/')
    }, [auth, route, navigate])
    useEffect(() => {
        if(auth && auth.token) getAccountInfo()
    }, [auth])
    if(route.document_title) {
        document.title = route.document_title
    }

    return <></>
})

export const RouterComponent = () => {
    return (<div style={{height: '100vh'}}><BrowserRouter>
        <Routes>
            {routes.map(route => {
                    return <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact !== undefined ? route.exact : true}
                    element={<Suspense fallback={null}>
                        {route.layout !== 'blank' && <NavBar className={route.navbarSpesificClass}/>}
                        <CheckViewAuth route={route}/>
                        <route.component/>
                        {!route.footer_disabled && <Footer/>}
                    </Suspense>}
                />
            })}
        </Routes>
    </BrowserRouter></div>)
}