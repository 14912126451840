import {FormattedMessage} from "react-intl";
import whiteLogo from '../_assets/Icons/meditopia-white-logo-only.svg'
import appstore from '../_assets/Icons/AppStore.svg'
import googleplay from '../_assets/Icons/GooglePlay.svg'
import {LanguageSelect} from "./_i18n";

import instagram from '../_assets/Icons/Instagram.svg'
import twitter from '../_assets/Icons/Twitter.svg'
import facebook from '../_assets/Icons/Facebook.svg'
import youtube from '../_assets/Icons/youtube.svg'
import pinterest from '../_assets/Icons/Pinterest.svg'
import linkedin from '../_assets/Icons/linkedin.svg'
import {connect} from "react-redux";

const socialLinks = [
    {
        src: instagram,
        url: 'https://www.instagram.com/meditopiapp/'
    },
    {
        src: twitter,
        url: 'https://twitter.com/meditopiapp'
    },
    {
        src: facebook,
        url: 'https://www.facebook.com/meditopiapp'
    },
    {
        src: pinterest,
        url: 'https://tr.pinterest.com/meditopiapp/_created/'
    },
    {
        src: linkedin,
        url: 'https://www.linkedin.com/company/meditopia'
    },
    {
        src: youtube,
        url: 'https://www.youtube.com/channel/UCrbJcGZx6RK9OlsvKyDfeiA'
    }
]

const LinkList = ({title, list}) => {
    return <div className={'col-6 col-md-4'}>
        <h5><FormattedMessage id={title}/></h5>
        {list.map(l => <a rel='noreferrer' target="_blank" key={`${title} ${l.url}`} href={l.url}><p>
            <FormattedMessage id={l.text}/>
        </p></a>)}
    </div>
}

const urlLists = [
    {
        title: 'COMPANY',
        list: [
            {
                url: 'https://meditopia.com/en/about/',
                text: 'ABOUT'
            },
            {
                url: 'https://meditopia.com/en/career/',
                text: 'CAREER'
            },
            {
                url: 'https://meditopia.com/en/legal/',
                text: 'LEGAL_NOTICE'
            },
            {
                url: 'https://meditopia.com/en/business/',
                text: 'FOR_WORK'
            }
        ]
    },
    {
        title: 'SERVICES',
        list: [
            {
                url: 'https://blog.meditopia.com/en/',
                text: 'BLOG'
            },
            {
                url: 'https://meditopia.com/en/subscription/free-trial/?utm_source&utm_medium&utm_campaign&utm_content&utm_term',
                text: 'SUBSCRIBE'
            },
            {
                url: 'https://meditopia.com/en/offers/newyeargift-2022/',
                text: 'GIFT'
            },
            {
                url: 'https://go.meditopia.com/en/signup',
                text: 'REDEEM_A_CODE'
            }
        ]
    },
    {
        title: 'MEMBER_HAPPINESS',
        list: [
            {
                url: 'https://help.meditopia.com/en/',
                text: 'HELP'
            }
        ]
    }
]
const mapToState = (state) => ({locale: state.i18n.locale})
const Footer = connect(mapToState)(({locale}) => {
    return <div className={'footer'}>
        <div className={'maxWith1200 space-between'}>
            <div className={'col-12 col-md-6 mt-4'}>
                <img src={whiteLogo} alt={'logowhite'}/>
            </div>
            <div className={'col-12 col-md-6 flex flex-wrap mt-4'}>
                {urlLists.map(u => <LinkList key={u.title} title={u.title} list={u.list}/>)}
            </div>
            <div className={'col-12 col-sm-12 p-3'}></div>
            <div className={'col-12 col-md-6 flex market-icons'}>
                <a target="_blank" rel='noreferrer' href={'https://apps.apple.com/us/app/meditopia-meditation-breathe/id1190294015'}><img src={appstore} alt={'apple'}/></a>
                <a target="_blank" rel='noreferrer' href={'https://play.google.com/store/apps/details?hl=en&id=app.meditasyon'}><img className={'ml-3'} src={googleplay} alt={'google'}/></a>
            </div>
            <div className={'col-12 col-md-6 flex flex-wrap'}>
                <LanguageSelect/>
            </div>

            <div className={'col-12 col-sm-12 p-3'}></div>
            <div className={'col-12 col-md-6 flex social-links'}>
                {socialLinks.map(s => <a key={'socials' + s.url} href={s.url}>
                    <img className={'ml-4'} alt={'social'} src={s.src}/>
                </a>)}
            </div>
            <div className={'col-12 col-md-6 flex flex-wrap'}>
                <div className={'flex ml-auto terms-and-conditions-links'}>
                    <a target={'_blank'} rel='noreferrer' className={'text-decoration-underline'} href={`https://meditopia.com/${locale}/terms-and-conditions/`}><FormattedMessage id={'TERMS_AND_CONDITIONS'}/></a>
                    <a target={'_blank'} rel='noreferrer' className={'ml-5 text-decoration-underline'} href={`https://meditopia.com/${locale}/privacy-policy/`}><FormattedMessage id={'PRIVACY_POLICY'}/></a>
                </div>
            </div>
        </div>
    </div>
})

export {Footer}

/*
<img
                className={'icon'}
                src={icon} alt={'briefIcon'}/>
            <p className={'copyright'}><FormattedMessage id={'FOOTER_COPYRIGHT'}/></p>
 */