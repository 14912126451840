import {
    Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav,
    Navbar,
    NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown,
} from "reactstrap";
import { connect} from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css';
import {logout} from "../_redux/actions/auth/auth.actions";
import {useNavigate} from "react-router-dom";
import mfwImage from '../_assets/Icons/purple.png'
import {useState} from "react";
import {Icons} from '../_components/icons'

const NavbarCollapsable = () => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const handleHover = (val) => {
        if(window.innerWidth > 768) {
            setIsOpen(val)
        }
    }
    return <NavItem onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)} className={'flex-wrap relative collapsable-link-container'}>
        <NavLink className={'collapsables-navLink cursor-pointer'} style={{display:"flex"}} onClick={() => {
            if(window.innerWidth > 768)
                window.location.href = 'https://meditopia.com/en/portfolio-of-offers/'
            else
                setIsOpen(!isOpen)
        }}>Our Products <Icons.downArrow style={{width: '19px', height:'18px', marginTop: '1px'}}/></NavLink>
        <a className={'view-all'} href="https://meditopia.com/en/portfolio-of-offers/" >View all</a>
        <DropdownMenu end className={`md:shadow border-0 md:absolute left top-[90%] md:py-4 md:px-3 ${isOpen && '!block'}`}>
            <DropdownItem href={'https://meditopia.com/en/forwork/mindfulness/'} className={'!bg-white md:text-center !ml-0'}>Mindfulness</DropdownItem>
            <DropdownItem href={'https://meditopia.com/en/forwork/1on1care/'} className={'mt-2 !bg-white md:text-center !ml-0'}>1-1 Personalized Care</DropdownItem>
        </DropdownMenu>
    </NavItem>
}

function NavBar({auth, className}) {
    const [toggle, setToggle] = useState(false)

    return (
        <Navbar light expand="md" className={`maxWith1200 ${className}`}>
                <NavbarBrand onClick={() => {
                    window.location = 'https://meditopia.com/en/forwork/'
                }} style={{cursor: 'pointer'}}><img src={mfwImage}/></NavbarBrand>

            <NavbarToggler onClick={() => setToggle(!toggle)} />
            <Collapse isOpen={toggle} navbar>
                <Nav className="ml-auto" navbar>
                    <NavbarCollapsable/>
                    <NavItem>
                        <NavLink href="/">Resources</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="https://blog.meditopia.com/en/work-life/">Blog</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="https://meditopia.com/en/signin/?redirectPage=regular">Login</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href={'https://meditopia.com/en/business-bookademo/'}>
                            <div className={'book-a-demo-button'}>BOOK A DEMO TODAY</div>
                        </NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
}

const mapToState = (state) => {
    return {auth: state.auth}
}

export default connect(mapToState)(NavBar);
