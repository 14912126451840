import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {SubmitButton} from "./Inputs/inputs";

const mapToState = (state) => ({locale: state.i18n.locale})

const FormlessBriefInfo = connect(mapToState)(injectIntl(({intl, locale, onComplete, template}) => {
    return <div className={`col-12 col-md-6 b2b-referral-form flex`}>
        <p className={'downloadable-content-badge'}>
            {template.badge}
        </p>
        <h4 className={'title-over-form'}>
            {template.title}
        </h4>
        <p className={'paragraph-over-form'}>
            {template.description}
        </p>
        <SubmitButton onClick={onComplete} text={template.submit_button_text}/>
    </div>
}))

export {FormlessBriefInfo}