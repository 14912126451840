import {store} from "../../store";

const {dispatch} = store

export const setAvailableLanguages = (languages) => {
    dispatch({
        type: 'SET_AVAILABLE_LANGUAGES',
        languages
    })
}

export const setLocale = (locale) => {
    dispatch({
        type: 'SET_I18N',
        locale
    })
}