const DownloadableContentDetails = ({template}) => {
    return <div className={'flex flex-wrap'} style={{width: '100%'}}>
        <div className={'col-12 col-md-6 downloadable-content-details'}>
            <h4>{template.details_title}, <span style={{color: '#834dc9'}}>{template.youWillLearn ? template.youWillLearn : "you'll learn"}</span></h4>
            <ul>
                {template.details_list && template.details_list.split('\n').map(t => <li>{t}</li>)}
            </ul>
        </div>
        <div className={'col-12 col-md-6 details-image'}>
            <img src={template.detail_image}/>
        </div>
    </div>
}

export {DownloadableContentDetails}