import {lazy} from "react";
import downloadableContentRoutes from "../../views/Downloadable-Content/pages/routes"

export const routes = [
    ...downloadableContentRoutes,
    {
        path: '/',
        name: 'Home',
        component: lazy(() => import('../../views/Home')),
        authRequired: false,
        document_title: 'Refer Your Company To Meditopia'
    },
    {
        path: '/allianzTR',
        name: 'Home',
        layout: 'blank',
        footer_disabled: true,
        component: lazy(() => import('../../views/Allianz')),
        authRequired: false,
        document_title: 'Refer Your Company To Meditopia'
    },
    {
        path: '/refer-company',
        name: 'Home',
        component: lazy(() => import('../../views/Refer-Company')),
        layout: 'blank',
        authRequired: false,
        document_title: 'Refer Your Company To Meditopia'
    },
    {
        path: '/e-book/edit_content_ffe0f9d6-e1b0-11ec-8fea-0242ac120002',
        name: 'E-Book',
        component: lazy(() => import('../../views/Downloadable-Content')),
        authRequired: false,
        document_title: 'Will',
        navbarSpesificClass: 'navbar-no-height'
    },
]