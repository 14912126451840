import { BackgroundedImagedContainer } from '../../_components/Containers/BackgroundImagedContainer'
import { Briefing } from '../../_components/General/Briefing'
import { B2bReferralForm } from '../../_components/downloadable-content-form'
import { EmptyContainer } from '../../_components/Containers/EmptyContainer'
import briefingIcon from '../../_assets/Icons/purple.png'
import eBook from '../../_assets/downloadable-content/e-book-3.png'
import { useEffect, useState } from 'react'
import { DownloadableContentDetails } from '../../_components/General/downloadable-content-details'
import { MeditopiaForWorkSection } from '../../_components/General/meditopia-for-work-section'
import {
  SuccessMessage,
  SuccessMessage2
} from '../../_components/General/SuccessMessage'
import { ContentEditor } from '../../_components/content_editor'
import { Helmet } from 'react-helmet'
import { scrollToSmoothly } from '../../_helpers/gimmicks'
import { FormlessBriefInfo } from '../../_components/formlessbriefInfo'
import RelatedContents from './Contents/related-contents'
import { Webinar } from '../../_components/webinar'
import webinarDefault from '../../_assets/downloadable-content/webinar-default.png'
import webinarDefaultMobile from '../../_assets/downloadable-content/webinar-default-mobile.png'
import { getUtmSource } from '../../_helpers/common'

const briefingNote = ({ img, data }) => {
  const { image_width, image_top, image_left, image_hue_disabled } = data

  const style = img
    ? data.isBigImage
      ? {
          width: '100%',
          top: `${image_top}%`,
          left: 0
        }
      : {
          width: `${image_width}%`,
          top: `${image_top}%`,
          left: `${image_left}%`
        }
    : {
        width: `${image_width}%`,
        top: `${image_top}%`,
        left: `${image_left}%`
      }

  return (
    <div className={'background-hue'}>
      <div
        className={'bgdiv'}
        style={image_hue_disabled ? { backgroundImage: 'unset' } : {}}></div>
      {img ? (
        <img src={img} style={style} />
      ) : (
        <img src={eBook} style={style} />
      )}
    </div>
  )
}

const resizeFormSticky = () => {
  const referralForm = document.getElementsByClassName('b2b-referral-form')[0]
  let sum = 0
  const classNames = ['backgroundImageContainer', 'content-details']
  if (window.innerWidth < 768)
    return referralForm ? (referralForm.style.height = ``) : null
  classNames.map((c) => {
    const elem = document.getElementsByClassName(c)[0]
    if (!elem) return
    sum += document.getElementsByClassName(c)[0].getBoundingClientRect().height
  })
  const cont = document.getElementsByClassName('b2b-referral-form')[0]
  if (!cont) return
  cont.style.height = `${sum}px`
}

const ReferCompany = ({ tmp }) => {
  useEffect(() => {
    scrollToSmoothly(0, 100)
  }, [])
  const [isFinished, setIsFinished] = useState(false)
  const [template, setTemplate] = useState(
    tmp || {
      badge: 'E-BOOK',
      title: 'How to Help Employees Overcome Burnout',
      webinar_about_title: 'About The Webinar',
      webinar_about2_title: 'Watch it to learn',
      webinar_date: new Date().toString(),
      description:
        'Address burnout early and often, and provide preventative resources for combating it, so everyone on your team can feel supported. This e-book includes practical tips for preventing burnout before starts.',
      details_title: 'In This e-book',
      details_list: `How to spot signs of burnout in your company, where burnout comes from, and how your company culture may be contributing to employee burnout.
Tips on helping employees recover from burnout, especially remote teams who may feel more isolated or unsupported.
How to spot signs of burnout in your company, where burnout comes from, and how your company culture may be contributing to employee burnout.
Tips on helping employees recover from burnout, especially remote teams who may feel more isolated or unsupported.`,
      submit_button_text: 'Download',
      form_title: 'Read the e-book now',
      page_title: 'Title',
      image: webinarDefault,
      webinar_mobile: webinarDefaultMobile,
      image_width: 40,
      image_top: 16,
      image_left: 30
    }
  )

  useEffect(() => {
    resizeFormSticky()
  }, [template])

  useEffect(() => {
    resizeFormSticky()
    setTimeout(resizeFormSticky, 100)
    window.addEventListener('resize', () => {
      resizeFormSticky()
      setTimeout(resizeFormSticky, 100)
    })
  }, [])

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'b2b_page_view',
      content_name: template.title,
      content_type: template.badge
    })
  }, [])

  const onComplete = () => {
    if (!template.formless) {
      setIsFinished(true)
      scrollToSmoothly(0, 0)
      setTimeout(() => {
        if (template.redirect_uri) {
          return window.open(template.redirect_uri)
        }
        window.open(`/downloadable-contents/${template.file}`, '_blank')
      }, 3000)
      return
    }

    window.open(`/downloadable-contents/${template.file}`, '_blank')
    let from = JSON.parse(localStorage.getItem('from'))
    if (!Array.isArray(from)) {
      from = []
    }
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'b2b_content_download',
      refer_info: {
        content_name: template.title,
        content_type: template.badge,
        from: getUtmSource() || from.join('\n') + '\n'
      }
    })
  }

  return (
    <div
      className={`col-md-12 p-0 page-container downloadable-content ${
        template.formless ? 'formless' : ''
      }`}>
      <Helmet>
        <title>{template.title}</title>
        <meta property="og:title" content={template.title} />
        <meta property="og:description" content={template.description} />
        <meta name="description" content={template.description} />
      </Helmet>
      {!tmp && <ContentEditor onChange={setTemplate} data={template} />}
      {template.webinar_mode && <Webinar data={template} />}
      {isFinished && !template.webinar_mode && (
        <BackgroundedImagedContainer
          bgColor={'#F6F5FF'}
          className={'downloadable-content-success'}>
          <div className={'brief-icon'}></div>
          <SuccessMessage2 data={template} title={'Thank you!'} />
        </BackgroundedImagedContainer>
      )}
      {!isFinished && !template.webinar_mode && (
        <BackgroundedImagedContainer
          bgColor={'white'}
          className={'formless-reverse corners-hue'}>
          <Briefing
            title={'EMPTY'}
            paragraphs={[]}
            Note={briefingNote}
            data={template}
            iconOverwrite={briefingIcon}
          />
          {template.formless && (
            <FormlessBriefInfo onComplete={onComplete} template={template} />
          )}
          {!template.formless && (
            <B2bReferralForm
              consent={true}
              onComplete={onComplete}
              template={template}
            />
          )}
        </BackgroundedImagedContainer>
      )}
      {!isFinished && !template.webinar_mode && (
        <EmptyContainer className={'content-details'}>
          <DownloadableContentDetails template={template} />
        </EmptyContainer>
      )}
      {!template.webinar_mode && <RelatedContents data={template} />}
      {!template.webinar_mode && (
        <EmptyContainer className={'pb-0'}>
          <MeditopiaForWorkSection />
        </EmptyContainer>
      )}
    </div>
  )
}

export default ReferCompany
