import {EmptyContainer} from "./Containers/EmptyContainer";
import {useState} from "react";

const ContentEditorValueTypes = {
    text: 'text',
    textarea: 'textarea',
    list: 'list',
    file: 'file',
    number: 'number',
    checkbox: 'checkbox',
    datetime: 'datetime-local'
}

const temp = {
    formless: {
        type: ContentEditorValueTypes.checkbox,
        name: 'Formless'
    },
    webinar_mode: {
        type: ContentEditorValueTypes.checkbox,
        name: 'Webinar'
    },
    webinar_url: {
        type: ContentEditorValueTypes.text,
        name: 'Webinar External Url',
        onlyActive: ['webinar_mode']
    },
    webinar_date: {
        type: ContentEditorValueTypes.datetime,
        name: 'Webinar Date',
        onlyActive: ['webinar_mode']
    },
    webinar_about_title: {
        type: ContentEditorValueTypes.text,
        name: 'Webinar About Title',
        onlyActive: ['webinar_mode']
    },
    webinar_about2_title: {
        type: ContentEditorValueTypes.text,
        name: 'Webinar Second About Title',
        onlyActive: ['webinar_mode']
    },
    webinar_mobile: {
        type: ContentEditorValueTypes.file,
        name: 'Webinar Mobile Image',
        onlyActive: ['webinar_mode']
    },
    page_title: {
        type: ContentEditorValueTypes.text,
        name: 'Page Title'
    },
    badge: {
        type: ContentEditorValueTypes.text,
        name: 'Badge'
    },
    title: {
        type: ContentEditorValueTypes.textarea,
        name: 'Title'
    },
    description: {
        type: ContentEditorValueTypes.textarea,
        name: 'Description'
    },
    details_title: {
        type: ContentEditorValueTypes.text,
        name: 'Details Title'
    },
    details_list: {
        type: ContentEditorValueTypes.list,
        name: 'Details List (Press "Enter" for new item)'
    },
    submit_button_text: {
        type: ContentEditorValueTypes.text,
        name: 'Submit Button Text'
    },
    form_title: {
        type: ContentEditorValueTypes.text,
        name: 'Form Title'
    },
    image: {
        type: ContentEditorValueTypes.file,
        name: 'Form Image'
    },
    image_width: {
        type: ContentEditorValueTypes.number,
        name: 'Image Width In %'
    },
    image_top: {
        type: ContentEditorValueTypes.number,
        name: 'Image Top In %'
    },
    image_left: {
        type: ContentEditorValueTypes.number,
        name: 'Image Left In %'
    },
    detail_image: {
        type: ContentEditorValueTypes.file,
        name: 'Form Image'
    },
}

const ContentEditor = ({template, onChange, data}) => {
    const [showJson, setShowJson] = useState(false)
    const [showEditor, setShowEditor] = useState(true)
    const handleChange = (e) => {
        if(e.target.type === ContentEditorValueTypes.datetime) {
            const date = new Date(e.target.value)
            //date.setMinutes(date.getMinutes() + new Date().getTimezoneOffset())
            return onChange({...data, [e.target.name]: date.toString()})
        }
        if(e.target.type === ContentEditorValueTypes.checkbox) {
            return onChange({...data, [e.target.name]: e.target.checked})
        }
        onChange({...data, [e.target.name]: e.target.value})
    }
    const handleFileInput = (e) => {
        let file = e.target.files[0]
        let baseURL = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            baseURL = reader.result;
            onChange({...data, [e.target.name]: baseURL})
        };
    }

    if(!showEditor) {
        return <button onClick={() => setShowEditor(!showEditor)} className={'z-40 fixed top-[60px] right-[20px] bg-purple-500 text-white px-5'}>Toggle Editor</button>
    }

    return <EmptyContainer className={'content-editor mt-[10vh]'}>
        <button onClick={() => setShowEditor(!showEditor)} className={'z-40 fixed top-[60px] right-[20px] bg-purple-500 text-white px-5'}>Toggle Editor</button>
        <h4 className={'w-9/12'}>Edit Contents</h4>
        {Object.keys(temp).map(key => {
            const field = temp[key]
            if(field.onlyActive && field.onlyActive.some(key => !data[key])) {
                return
            }
            return <div className={'col-12 col-md-6 p-2'}>
                <label>{field.name || key}</label>
                {field.type === ContentEditorValueTypes.checkbox && <><br/><input style={{marginRight: 'auto', width: 'fit-content'}}
                    type={field.type} checked={data[key]} name={key} onChange={handleChange} placeholder={field.name || key}>
                </input></>}
                {(field.type === ContentEditorValueTypes.textarea || field.type === ContentEditorValueTypes.list) && <textarea
                    type={field.type} value={data[key]} name={key} onChange={handleChange} placeholder={field.name || key}>
                </textarea>}
                {field.type == ContentEditorValueTypes.text && <input type={field.type} value={data[key]} name={key} onChange={handleChange}
                        placeholder={field.name || key}/>}
                {field.type == ContentEditorValueTypes.datetime && <input type={field.type} name={key} onChange={handleChange}
                                                                      placeholder={field.name || key}/>}
                {field.type == ContentEditorValueTypes.number && <input type={field.type} value={data[key]} name={key} onChange={handleChange}
                                                                      placeholder={field.name || key}/>}
                {field.type == ContentEditorValueTypes.file && <input type={field.type} name={key} onChange={handleFileInput}/>}
            </div>
        })}

        <button onClick={() => {
            setShowJson(!showJson)
        }}>Show Template</button>
        {showJson && <p>{JSON.stringify({...data, image:'not visible', detail_image:'not_visible'})}</p>}
    </EmptyContainer>
}

export {ContentEditor}