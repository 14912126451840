import {FormattedMessage} from "react-intl";
import successIcon from '../../_assets/Icons/streamline-icon-check-circle-1@48x48.SVG'
import linkedIn from '../../_assets/downloadable-content/LinkedIn.png'
import linkedInWhite from '../../_assets/downloadable-content/linkedinWhite.svg'

export const SuccessMessage = ({title, shortMessages}) => {
    return <div className={'success-message-component'}>
        <img src={successIcon} alt={'success'}/>
        <h4><FormattedMessage id={title}/></h4>
        {shortMessages.map(s => <p><FormattedMessage id={s}/></p>)}
        <p style={{display: 'flex', width: 'fit-content', marginInline: 'auto', marginTop: '30px', background: '#0A66C2',
            boxShadow: '0px 0px 14px rgb(0 0 0 / 10%)',
            borderRadius: '32.5px',
            padding: '9px 27px 9px 33px',
            color: 'white'}}>Follow us on <a style={{marginLeft: '5px', marginTop: '2px'}} href={'https://www.linkedin.com/company/meditopia/'} target={'_blank'}><img style={{marginLeft: '10px'}} src={linkedInWhite}/></a></p>
    </div>
}

export const SuccessMessage2 = ({title, data}) => {
    return <div className={'success-message-component'}>
        <img src={successIcon} alt={'success'}/>
        <h4><FormattedMessage id={title}/></h4>
        <p>
            <br/><span style={{color: 'rgb(131, 77, 201)'}}>'{data.title}'</span>
        </p>
        <p><a href={data.redirect_uri ? data.redirect_uri : `/downloadable-contents/${data.file}`} target={'_blank'} className={'mr-1'}>Click here </a>
            {data.redirect_uri ? "if you are not redirected in a few seconds.": "if download is not started in a few seconds"}
        </p>
        <p style={{display: 'flex', width: 'fit-content', marginInline: 'auto', marginTop: '30px', background: '#0A66C2',
            boxShadow: '0px 0px 14px rgb(0 0 0 / 10%)',
            borderRadius: '32.5px',
            padding: '9px 27px 9px 33px',
            color: 'white'}}>Follow us on <a style={{marginLeft: '5px', marginTop: '2px'}} href={'https://www.linkedin.com/company/meditopia/'} target={'_blank'}><img style={{marginLeft: '10px'}} src={linkedInWhite}/></a></p>
    </div>
}