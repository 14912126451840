import image from "../../../_assets/downloadable-content/CatalogImage_MHD_Final.png"
//import imageMobile from "../../../_assets/downloadable-content/Mental Health Day - Catalog Mobile copy.png"

export default {
    "badge":"Webinar",
    "theme_color": "#ebe9ff",
    "title":"Introduction to Mindfulness",
    "webinar_about_title":"Introduction to Mindfulness",
    "webinar_about2_title":"What you’ll learn:",
    "webinar_image_class": "mb-[-13px]",
    "webinar_type": "PUBLIC WEBINAR",
    "webinar_max_bg_height": "md:max-h-[710px]",
    "webinar_date":"Mon Oct 10 2022 19:00:00 GMT+0300 (GMT+03:00)",
    "description":"Almost 2.500 years ago a mythical practiced was born. It was used by people to remain calmed during challenging times. But mostly, to connect with their senses to fully enjoy the present. It’s called mindfulness, and in this webinar we will travel through the science and secrets behind it.\n\nOur guide in this adventure is Deniz Bağan. Organic Intelligence facilitator, keynote speaker for mindfulness and self-compassion, and Meditopia’s Meditation Expert and Content Creator.",
    "details_title":"What you’ll learn:",
    "details_list":"What is mindfulness\nWhat happens in your brain and body when practicing it\nIts effects on stress, sleep and anxiety\nHow to break the Past-Future judgment cycle\nHow to practice mindfulness right away with Meditopia’s app",
    "submit_button_text":"Watch",
    "form_title":"Watch the webinar now",
    "page_title":"Introduction to Mindfulness",
    "image":image,
    "webinar_mobile":image,
    "image_width":40,
    "image_top":16,
    "image_left":30,
    "webinar_mode":false,
    "detail_image":"not_visible",
    url: '/downloadable-content/introduction-to-mindfulness',
    "webinar_url": "https://lnkd.in/dpMujb32",
    formless: false,
    isBigImage: true
}