import {FormattedMessage} from "react-intl";
import icon from '../../_assets/Icons/meditopia-for-work-white.png'

const Briefing = ({title, paragraphs, Note, note, iconOverwrite, data}) => {
    return <div className={'col-12 col-md-6 briefing'}>
        {/*<div className={'brief-icon'}><img
            src={iconOverwrite || icon} alt={'briefIcon'} className={'brief-icon'}/>
        </div>*/}
        <h4><FormattedMessage id={title}/></h4>
        {paragraphs.map((paragraph, i) => <p key={`brief_parag_${i}`}><FormattedMessage id={paragraph}/></p>)}
        {Note && <Note img={data.image} data={data}/>}
        {note}
    </div>
}

export {Briefing}