import image from "../../../_assets/downloadable-content/logo-interview.png"

export default {
    "theme_color": "#ccb8edab",
    "badge":"Webinar",
    "badge_text": 'Turkish',
    "title":"The mental well-being necessities of teams in software industry organizations",
    "webinar_about_title":"About The Webinar",
    "webinar_about2_title":"Watch it to learn",
    "description":"The software industry is often under the influence of high burnout and anxiety levels. This, however, is not Logo’s case. As leaders in the industry since 1984, Logo included Meditopia’s Mindfulness Program as part of their work benefits. And in this webinar, you will discover why we achieved a 97% activation rate and how Logo employees now feel, sleep, and work better.",
    "details_title":"In this webinar",
    "details_list":"Why successful companies need employee mental health support\nWhat is the Logo Wellness Program, and why Meditopia for Work was included in it\nWhy Meditopia’s content was quickly welcomed and recommended among the team\nHow the benefits on their mental well-being at work were measured",
    "submit_button_text":"Watch",
    "form_title":"Watch the webinar now",
    "page_title":"The mental well-being necessities of teams in software industry organizations",
    "image":image,
    "webinar_mobile":"/static/media/webinar-default-mobile.13bd98f1609732fbaffa.png",
    "image_width":100,
    "image_top":0,
    "image_left":0,
    "detail_image":"not_visible",
    url: '/downloadable-content/mental-well-being-necessities-of-teams-in-software',
    "redirect_uri": "https://youtu.be/ziALhv-0wHU",
    listButtonText: 'Watch now'
}