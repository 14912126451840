import image from '../../../_assets/downloadable-content/Meditopia_PublicisGroupe_Logo.png'
import detailImage from '../../../_assets/downloadable-content/Publicis_TeaserImage.png'

export default {
  theme_color: 'rgb(247 245 255)',
  use_second_image: true,
  formless: false,
  badge: 'Case Study',
  title: 'Publicis: From "Stress-zone" to "Zen-Zone"',
  description:
    'Publicis Groupe made a partnership to support their team against their mental health adversaries (burnout, motivation, performance fluctuation). Download the case study below, and discover how Meditopia helped them achieve a work-life balance with an organizational wellness approach.',
  details_title: 'In this case study',
  details_list:
    'Who are Publicis Groupe and Meditopia \nWhy Publicis Groupe is interested in expanding its mental health support and their challenges\nWhich Meditopia programs they benefited from, their success metrics, and more!',
  submit_button_text: 'Download case study',
  form_title: 'Read Publicis Groupe’s Case Study Now',
  page_title: 'Publicis: From "Stress-zone" to "Zen-Zone"',
  file: 'PublicisGroupe_Meditopia_CaseStudy_final.pdf',
  image_width: 70,
  image_top: 36,
  image_left: 15,
  image: image,
  detail_image: detailImage,
  url: '/downloadable-content/publicis-case-study'
}
