import TheEfficancyStudy from './the-efficacy-study-how-meditopia-enhances-mental-well-being'
import ThreeSteps from './3-steps-to-create-a-culture-of-well-being'
import AllianzCaseStudy from "./allianz-case-study";
import TheBenefitsOfEmployeeWellbeing from "./the-benefits-of-employee-wellbeing";
import NyMets from "./nyMets"
import mfulnessAndPractical from "./mindfulness-and-practical-tips";
import publicis from "./publicis";
import nyMetsWebinaar from "./ny-mets-webinaar";
import protectingWhatMatters from "./protecting-what-matters";
import retainTalentBeyondSalary from "./retain-talent-beyond-salary";
import IntroductionToMindfullnes from "./introduction-to-mindfullnes";
import MindfulnessSupportToIncreaseIt from "./mindfulness-support-to-increase-it";
import PrivateWebinarsToEnhance from "./Private-webinars-to-enhance";
import logoInterview from "./logo-interview";
import ApplianceOfInclusion from "./ApplianceOfInclusion";
import MovementsForRemoteWorkers from "./movements-for-remote-workers";
import FireflyCaseStudy from "./firefly-casestudy";
import AnadoluEfesCaseStudy from './anadoluefes-casestudy'

export default {
    MovementsForRemoteWorkers,
    ApplianceOfInclusion,
    PrivateWebinarsToEnhance,
    logoInterview,
    MindfulnessSupportToIncreaseIt,
    IntroductionToMindfullnes,
    nyMetsWebinaar,
    protectingWhatMatters,
    publicis,
    AllianzCaseStudy,
    TheBenefitsOfEmployeeWellbeing,
    NyMets,
    mfulnessAndPractical,
    ThreeSteps,
    FireflyCaseStudy,
    AnadoluEfesCaseStudy,
    TheEfficancyStudy
}