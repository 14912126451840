import logo from '../../../_assets/downloadable-content/webinar-sevil.png'
import image from '../../../_assets/downloadable-content/logologo.png'

export default {
    "badge": "PRIVATE WEBINAR",
    "title": "Movement for remote workers: improve your physical & mental health",
    "theme_color": "rgb(204 184 237 / 48%)",
    "webinar_about_title": "About The Webinar",
    "webinar_about2_title": "Watch it to learn",
    "description": "Remote and hybrid environments are an advantage for both employees and companies. But how are teams supporting their physical health? How can we minimize remote work’s impact on their sedentary and movement behaviors? Discover this and more on our next webinar!",
    "details_title": "In Webinar",
    "details_description": "Our guide will be Sevil Topuzca, Meditopia's Mindfulness Expert & Content Manager. And together, we will navigate:",
    "details_list": "The concept of movement\nThe impact of remote/hybrid work environments on the physical and mental health\nFactors impacting our movement when working from home\nBenefits of exercise and movement\nTips for implementing them ",
    "submit_button_text": "Contact us to learn more",
    "form_title": "And more! Tap below to join us.",
    "page_title": "Movement for remote workers: improve your physical & mental health",
    "image": logo,
    "webinar_mobile": logo,
    "add_image_padding": true,
    "image_width": 40,
    "image_top": 16,
    "image_left": 30,
    "formless": true,
    "webinar_mode": true,
    "detail_image": "not_visible",
    url: '/downloadable-content/movement-for-remote-workers',
    "webinar_url": "https://meditopia.com/en/business-bookademo/",
    "list_flat": false
}