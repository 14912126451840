const initialState = {
    locale: 'en',
    availableLanguages: []
}

const i18n = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_AVAILABLE_LANGUAGES':
            return {
                ...state,
                availableLanguages: action.languages
            }
        case 'SET_I18N':
            return {
                ...state,
                locale: action.locale
            }
        default:
            return state
    }
}

export {i18n}
