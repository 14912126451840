import './App.css';
import {RouterComponent} from "./_router/Router";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Provider} from "react-redux";
import {store} from "./_redux/store";
import {ModalContainer} from "./_components/Modals/ModalHandler";
import {I18nProvider} from "./_components/_i18n";
function App() {
  return (
    <Provider store={store}>
        <I18nProvider>
            <ModalContainer/>
            <ToastContainer/>
            <RouterComponent/>
        </I18nProvider>
    </Provider>
  );
}

export default App;
