import logo from '../../../_assets/downloadable-content/Logoweb.png'
import image from '../../../_assets/downloadable-content/logologo.png'

export default {
  theme_color: 'rgb(247 245 255)',
  badge: 'Case Study',
  title: "Cracking the Code to Logo's Workplace Well-being",
  webinar_about_title: 'About The Webinar',
  webinar_about2_title: 'Watch it to learn',
  description:
    'Logo has been one of the leading companies in the software industry since 1984. They quickly understood the importance of mental well-being as part of their company’s culture, and with Meditopia’s partnership, they discovered a new level of employee loyalty and work efficiency. Download their case study and find out how Meditopia’s mindfulness program influenced their team’s performance.',
  details_title: 'In this case study',
  details_list:
    'Who are Logo and their wellness organizational efforts\nThe challenges they had in the matter\nHow Meditopia enhanced the life of 250+ of their employees\nTheir specific mental health at the workplace achievements',
  submit_button_text: 'Nice! Let me read it.',
  form_title: 'Nice! Let me read it.',
  page_title: "Cracking the Code to Logo's Workplace Well-being",
  image: image,
  webinar_mobile:
    '/static/media/webinar-default-mobile.13bd98f1609732fbaffa.png',
  image_width: 78,
  image_top: 38,
  image_left: 11,
  formless: false,
  detail_image: logo,
  use_second_image: true,
  file: 'Logo-CaseStudy.pdf',
  url: '/downloadable-content/mindfulness-support-to-increase-it'
}
