import image from "../../../_assets/downloadable-content/WebPage-catalog.png";
import detailImage from "../../../_assets/downloadable-content/Allianz_CaseStudy_Web.png"

export default {
    "theme_color": "#7e5ec480",
    "badge":"PRIVATE Webinar",
    "title":"The meaning and appliance of inclusion in the workplace",
    "webinar_about_title":"The meaning and appliance of inclusion in the workplace",
    "webinar_about2_title":"What you’ll learn",
    "description":"Community webinars are tools to grow together. Anyone interested in a subject can join, learn, and share the information with their network to spread awareness and, sometimes, even help someone have a better day.\n\nFor this edition, we will count on Adrián Sarasa, Diversity & Inclusion Advocate and Head of User Acquisition of Meditopia. Together, we will review key concepts of inclusion and diversity, answer your questions, and explore ways to support people around us through a more inclusive work culture.",
    "details_title":"In This webinar",
    "details_list":"What is and what isn’t inclusion\nStats of inclusion in the world\nHow to develop a more inclusive work culture\nTips to become a better ally",
    "submit_button_text":"Contact us to learn more",
    "form_title":"Read the e-book now",
    "page_title":"The meaning and appliance of inclusion in the workplace",
    "image":image,
    "webinar_mobile":image,
    "image_width":40,
    "image_top":16,
    "image_left":30,
    "formless":true,
    "webinar_mode":true,
    "detail_image":"not_visible",
    url: '/downloadable-content/the-meaning-and-appliance-of-inclusion',
    "webinar_url": "https://meditopia.com/en/business-bookademo/",
}