import {store} from "../../store";
import {GET, POST} from "../../../_helpers/apiHelper";
import {toast} from "react-toastify";

const {dispatch} = store

export const login = async (email, password) => {
    const data = await POST('/users/login', {
        email,
        password
    })

    dispatch({
        type: 'LOGIN',
        authInfo: data
    })
    getAccountInfo()
}

export const register = async (name, surname, email, password) => {
    await POST('/users/register', {
        name,
        surname,
        email,
        password
    })

    toast.success('Success!')
}

export const logout = async () => {
    dispatch({
        type: 'LOGOUT'
    })
}

export const getAccountInfo = async () => {
    const data = await GET('/users/account-info')

    dispatch({
        type: 'SET_ACCOUNT_INFO',
        account_info: data
    })
}