import image from '../../../_assets/downloadable-content/firefly-logo.png'
import detailImage from '../../../_assets/downloadable-content/firefly-casestudy.png'

export default {
  theme_color: 'rgb(103 79 193)',
  use_second_image: true,
  formless: false,
  badge: 'Case Study',
  title: "Mindfulness Makes Firefly's Well-being Spark Brighter",
  description:
    'Firefly, founded in 2017, is a company that develops outdoor mobility advertising in the most impactful U.S. markets. Due to competitive market dynamics, their employees are vulnerable to experiencing anxiety, burnout, and performance fluctuation unless preventable mental well-being support measures are implemented. Read to discover how Meditopia’s personalized mindfulness program and mental health training helped them to lower insurance costs, increase productivity, improve retention, and create a more positive work environment.',
  details_title: 'In This Case Study',
  details_list:
    'The challenges a company like Firefly has regarding employee mental well-beingt\nWhat solutions Meditopia provided to prevent burnout and improve performance\nHow can we effectively support your team’s mental health ',
  submit_button_text: 'Download',
  form_title: 'Read the case study for free',
  file: 'Firefly_Casestudy.pdf',
  page_title: "Mindfulness Makes Firefly's Well-being Spark Brighter",
  image: image,
  image_width: 92,
  image_top: 42,
  image_left: 10,
  image_hue_disabled: true,
  detail_image: detailImage,
  url: '/downloadable-content/firefly-case-study'
}
