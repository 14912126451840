import axios from "axios"
import {toast} from "react-toastify"
import {store} from "../_redux/store";

const apiUrl = process.env.REACT_APP_API_URL
const uninterceptAxios = axios.create()

const getBearer = (config = {}) => {
    const { auth } = store.getState()
    let bearerConfig = {}
    if (auth.role !== 'logged_out') {
        const { token } = auth
        bearerConfig = {
            headers: config.headers ? { Authorization: `Bearer ${token}`, ...config.headers } : { Authorization: `Bearer ${token}`}
        }
    }
    return bearerConfig
}

const handleError = (error) => {
    if (error.response) {
        toast.error(JSON.stringify(error.response.data.message))
        return
    }
}

const promisify = (req, silent) => {
    return new Promise(async (resolve, reject) => {
        req.then((response) => {
            resolve(response.data)
        }).catch((error) => {
            if(!silent) {
                handleError(error)
            }
            reject(error.response.data)
        })
    })
}

export const GET = (url, params, config = {}) => {
    return promisify(uninterceptAxios.get(`${apiUrl}${url}`, {...getBearer(config), params}), null)
}

export const POST = (url, data, config, silent) => {
    return promisify(uninterceptAxios.post(`${apiUrl}${url}`, data, {...getBearer(config)}), silent)
}