import calendarPurple from "../_assets/downloadable-content/icons/calendar-purple.svg"
import clockPurple from "../_assets/downloadable-content/icons/clock-purple.svg"

const monthNames = ["January","February","March","April","May","June","July",
    "August","September","October","November","December"];
const monthShortHand = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

export const getShortDateString = (date, onlyNumbers = false) => {
    const d = new Date(date)
    if(onlyNumbers) {
        const date1 = d.getDate()
        const month = d.getMonth() + 1
        return `${date1 < 10 ? '0' + date1: date1}.${month < 10 ? '0' + month : month}.${d.getFullYear()}`
    }
    return `${monthShortHand[d.getMonth()].toUpperCase()} ${d.getDate()} ${d.getFullYear()}`
}

const nth = function(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
    }
}

export const Webinar = ({data}) => {
    const { list_flat, add_image_padding, details_description, submit_button_text, webinar_date, webinar_mobile, webinar_about_title, webinar_image_class, webinar_max_bg_height, description, webinar_about2_title, details_list, webinar_url} = data
    const date = new Date(webinar_date)
    return <div className={'w-full position-relative mt-[30px]'}>
        <div className={`bg-[#F0E9FD] md:absolute top-0 left-0 right-0 ${webinar_max_bg_height || 'md:max-h-[520px]'} overflow-hidden`}>
            <div className={'w-full pt-[20%] md:pt-[58%]'}>
                <img src={webinar_mobile} className={`md:hidden mb-[50px] ${webinar_image_class} ${add_image_padding && 'w-[90%] mx-auto'}`}/>
            </div>
        </div>
        <div className={'maxWith1200 m-inline mt-[30px] md:mt-[10vh]'}>
            <img className={`${add_image_padding ? 'pt-[5%] pb-[5%] md:w-[60%] md:max-w-[720px] mx-auto drop-shadow-2xl': 'w-full'} max-w-4xl m-inline w-full z-10 hidden md:block`} src={data.image}/>
            <div className={'w-full z-10'}>
                {webinar_date && <div className={'flex m-inline w-fit webinar-date-container md:mt-[-35px]'}>
                    <img src={calendarPurple} className={'w-[25px] mr-[15px]'}/>
                    {webinar_date && `${monthNames[date.getMonth()]} ${date.getDate()}${nth(date.getDate())}, ${date.getFullYear()}`}
                    <div className={'webinar-date-vr'}></div>
                    <img src={clockPurple} className={'w-[25px] mx-[15px]'}/>
                    {webinar_date && date.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}
                </div>}
            </div>
        </div>
        <div className="max-w-[610px] m-inline w-full mt-[30px] p-[30px] sm:p-[30px] md:p-0">
            <h1 className={'font-bold text-[24px]'}>{webinar_about_title}</h1>
            <p className={'text-[16px] whitespace-pre-line'}>{description}</p>
            <h1 className={'font-bold text-[24px] mt-[30px]'}>{webinar_about2_title}</h1>
            {details_description && <p style={{whiteSpace: 'break-spaces'}}>
                {typeof details_description === 'function' ? details_description(): details_description}
            </p>}
            <ul className={`${list_flat ? 'list-none pl-0': 'list-disc'} mb-0`}>
                {details_list && details_list.split('\n').map(t => {
                    if(t === 'And more!') return null
                    if(t.includes('/ub/')) {
                        const splitted = t.split('/ub/')
                        return <li className={'mt-1 text-[16px]'}><span className={'underline'}>{splitted[0]}</span> {splitted[1]}</li>
                    }
                    return <li className={'mt-1 text-[16px]'}>{t}</li>
                })}
            </ul>
            {details_list && details_list.includes('And more!') && <p className={'mt-2 ml-[16px] text-[16px]'}>And more!</p>}
            <button onClick={() => window.open(webinar_url, '_blank')} className={'text-white mb-[30px] bg-[#834CC9] px-5 w-full md:w-fit'}>
                {submit_button_text && !["Watch the Event", "Save Your Seat"].includes(submit_button_text) ? submit_button_text : (new Date().getTime() > new Date(webinar_date).getTime() ? "Watch the Event": "Save Your Seat")}
            </button>
        </div>
    </div>
}