const initialState = {
    key: '',
    modalData: null
}

const modal = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MODAL':
            return {
                ...state,
                key: action.key,
                modalData: action.modalData
            }
        case 'CLOSE_MODAL':
            return {
                ...state,
                key: '',
                modalData: null
            }
        default:
            return state
    }
}

export {modal}
