import {Input} from "reactstrap";
import * as i18nCountries from "i18n-iso-countries"
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";

const mapToState = (state) => ({i18n: state.i18n})

const CountrySelect = injectIntl(connect(mapToState)(({id, name, onChange, value, i18n, intl, onFocus, onBlur, className}) => {
    const {locale, availableLanguages} = i18n;
    const [refreshComponent, setRefreshComponent] = useState('')
    useEffect(() => {
        availableLanguages.map(lan => {
            const current = i18nCountries.getNames(lan)
            if(Object.keys(current).length === 0) {
                i18nCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
            }
            return '-'
        })
        setRefreshComponent(new Date().getTime())
    }, [availableLanguages])
    const countries = i18nCountries.getNames(locale)
    return <>
        <div style={{display: 'none'}}>{refreshComponent}</div>{/*Just to remove warning*/}
        <Input type="select" className={className} value={value} name={name} onFocus={onFocus} onBlur={onBlur} id={id} onChange={onChange}>
        <option value='NONE' disabled>{/*intl.formatMessage({id: 'PLEASE_SELECT'})*/}</option>
        {Object.keys(countries).map(code => <option key={`${name}-${code}`} value={countries[code]}>{countries[code]}</option>)}
    </Input></>
}))

export {CountrySelect}