import image from '../../../_assets/downloadable-content/Mindfulness webinaar.png'

export default {
    "badge":"Webinar",
    "title":"Mindfulness and Practical Tips to Manage Stress and Improve Focus",
    "description":"Here’s the ugly truth; stress will always be a part of our lives. But here’s the good news; Just like we train our bodies to achieve physical challenges, we can also train our minds to be stronger and more resilient in the face of everyday stressors.\n\nIn this webinar, we’ll interview Dr. Banu Cankaya, psychologist and researcher on easy-to-implement ways to increase your long-term resilience to stress and overcome those everyday overwhelming moments.",
    "details_title":"In this webinar",
    "youWillLearn": "we’ll cover specifically",
    "details_list":"How to increase your long-term resilience to stress\nHow to start your day in a way that prevents stressful moments\nDealing with triggering and overwhelming moments during your workday\nHow to end the workday and complete the stress cycle so that you can be fully present for your loved ones and yourself",
    "submit_button_text":"Open",
    "form_title":"Watch the webinar now",
    "page_title":"Mindfulness and Practical Tips to Manage Stress and Improve Focus",
    "image_width":"87",
    "image_top":"2",
    "image_left":"7",
    "image":image,
    "redirect_uri": "https://youtu.be/vMP7YKkdl1c",
    url: '/downloadable-content/mindfulness-and-practical-tips-to-manage',
    listButtonText: 'Watch now'
}