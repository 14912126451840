import {combineReducers} from "redux";
import {auth} from "./auth/auth.reducer";
import {modal} from "./modal/modal.reducer";
import {i18n} from "./i18n/i18n.reducer";

const rootReducer = combineReducers({
    auth,
    modal,
    i18n
})

export {rootReducer}