import {Button, FormGroup, Input, Label} from "reactstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {CountrySelect} from "../General/CountrySelect";
import {useState} from "react";

const ErrorLabel = ({error, label, value}) => {
    return error ? <div className={'input-error-label'}>
        <FormattedMessage
            id={error}
            values={{
                field_name: <FormattedMessage id={label}/>,
                length: value ? value.length : 0
            }}/>
    </div> : null
}

export const TextInput = ({name, value, label, required, onChange, lg, p, errors, maxLength}) => {
    const [focus, setFocus] = useState(false)
    const error = errors[name]
    return <><FormGroup className={`relative p-1 col-12 col-lg-${lg || '12'} ${p || ''}`}>
        <Label for={name} className={`label-in-input ${focus || value ? 'label-on-focus': ''}`}><FormattedMessage id={label}/></Label>
        <Input
            maxLength={maxLength || 50}
            type="text"
            className={`${error && 'input-error'}`}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            name={name} id={name} value={value} required={required} onChange={onChange}/>
        <ErrorLabel label={label} error={error} value={value}/>
    </FormGroup></>
}

export const EmailInput = ({name, value, label, required, onChange, errors, lg}) => {
    const [focus, setFocus] = useState(false)
    const error = errors[name]
    return <><FormGroup className={`relative col-12 col-lg-${lg || '12'} p-1`}>
        <Label className={`label-in-input ${focus || value ? 'label-on-focus': ''}`} for={name}><FormattedMessage id={label}/>*</Label>
        <Input type="email"
               className={`${error && 'input-error'}`}
               onFocus={() => setFocus(true)}
               onBlur={() => setFocus(false)}
               value={value} name={name} id={name} required={required} onChange={onChange}/>
        <ErrorLabel label={label} error={error} value={value}/>
    </FormGroup><br/></>
}

export const CountryInput = ({name, value, label, required, onChange, errors}) => {
    const [focus, setFocus] = useState(false)
    const error = errors[name]
    return <><FormGroup className={'p-1 relative'}>
        <Label className={`label-in-input ${focus || value !== 'NONE' ? 'label-on-focus': ''}`} for={name}><FormattedMessage id={label}/>*</Label>
        <CountrySelect name={name}
                       className={`${error && 'input-error'}`}
                       onFocus={() => setFocus(true)}
                       onBlur={() => setFocus(false)}
                       id={name} required={required} value={value} onChange={onChange}/>
        <ErrorLabel label={label} error={error} value={value}/>
    </FormGroup></>
}

export const SelectInput = injectIntl(({name, value, label, required, onChange, intl, options, errors}) => {
    const [focus, setFocus] = useState(false)
    const error = errors[name]
    return <><FormGroup className="p-1 relative">
        <Label className={`label-in-input ${focus || value !== 'NONE' ? 'label-on-focus': ''}`} for={name}><FormattedMessage id={label}/>*</Label>
        <Input type="select"
               className={`${error && 'input-error'}`}
               onFocus={() => setFocus(true)}
               onBlur={() => setFocus(false)}
               value={value} name={name} id={name} onChange={onChange}>
            <option value='NONE' disabled>{/*intl.formatMessage({id: 'PLEASE_SELECT'})*/}</option>
            {options.map(o => <option value={intl.formatMessage({id: o, defaultMessage: o})} key={`${name}-${o}`}>{intl.formatMessage({id: o, defaultMessage: o})}</option>)}
            <option value="OTHER">{intl.formatMessage({id: 'OTHER'})}</option>
        </Input>
        <ErrorLabel label={label} error={error} value={value}/>
    </FormGroup></>
})

export const CheckInput = ({name, value, label, labelValues, required, onChange, errors}) => {
    const error = errors[name]
    return <><FormGroup check>
        <Label check>
            <Input type="checkbox" name={name} value={value} id={name} required={required} className={'check-box-margin'} onChange={onChange}/>{' '}
            <FormattedMessage id={label} values={labelValues}/>
        </Label>
        <ErrorLabel label={label} error={error} value={value}/>
    </FormGroup><br/></>
}

export const SubmitButton = ({onClick, text='SUBMIT'}) => {
    if(text == '') text = ' '
    return <Button type={'submit'} onClick={onClick} onSubmit={onClick}><FormattedMessage id={text}/></Button>
}