const authInfo = JSON.parse(localStorage.getItem('infoAndToken'))
const initialState = {
    ...authInfo
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            localStorage.setItem('infoAndToken', JSON.stringify(action.authInfo))
            return {
                ...state,
                ...action.authInfo
            }
        case 'LOGOUT':
            localStorage.removeItem('infoAndToken')
            return {  }
        case 'SET_ACCOUNT_INFO':
            return {
                ...state,
                account_info: action.account_info
            }
        default:
            return state
    }
}

export {auth}
