import contents from '../Contents'
import Page from '../index'

export default [
    ...Object.values(contents).map((c) => ({
        path: c.url,
        name: c.title,
        component: () => <>
            <Page tmp={c}/>
        </>,
        authRequired: false,
        navbarSpesificClass: 'navbar-no-height'
    }))
]