import image from "../../../_assets/downloadable-content/alianz-case-study.png";
import detailImage from "../../../_assets/downloadable-content/Allianz_CaseStudy_Web.png"

export default {
    "theme_color": "rgb(103 79 193)",
    "use_second_image": true,
    "formless": false,
    "badge":"Case Study",
    "title":"Allianz Well-Being Is Now Ensured",
    "description":"In 2021, Allianz and Meditopia for Work partnered to provide mental health support to their insurance customers, especially during the challenging pandemic conditions. With full access to Meditopia’s app and real-time data, the partnership has reached an outstanding engagement and increasing NPS",
    "details_title":"In This Case Study",
    "details_list":"How we achieved an impressive 60% activation rate\nWhy Allianz decided to increase renewals by 12x\nHow Meditopia supported Allianz’s team through personalized content and communications",
    "submit_button_text":"Download",
    "form_title":"Read the case study for free",
    "file": "Allianz_CaseStudy.pdf",
    "page_title":"Allianz Well-Being Is Now Ensured",
    image: image,
    image_width: 92,
    image_top: 42,
    image_left: 10,
    image_hue_disabled: true,
    detail_image: detailImage,
    url: '/downloadable-content/alianz-case-study'
}