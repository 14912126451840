import image from '../../../_assets/downloadable-content/Efficacy-Study.png'

export default {
  theme_color: 'rgb(94 179 236 / 67%)',
  badge: 'Whitepaper',
  title: 'The Efficacy Study: How Meditopia Enhances Mental Well-being',
  description:
    'Our unique, science-based approach, enriched with cultural relevance, creates a measurable global impact. Read and discover how Meditopia can reduce stress and boost self-confidence in just eight weeks.',
  details_title: 'In this whitepaper',
  details_list:
    "Meditopia's reach and impact among its users globally\nThe methods and strategies that inspire our well-being programs\nThe 8-week program outline and its long-lasting results\nDetailed pre and post-program key findings\nUsers' perception of the program",
  submit_button_text: 'Download',
  form_title: 'Read the guidebook for free',
  file: 'the-efficacy-study-how-meditopia-enhances-mental-well-being.pdf',
  image: image,
  image_width: 87,
  image_top: -11,
  image_left: 7,
  url: '/downloadable-content/the-efficacy-study-how-meditopia-enhances-mental-well-being',
  listButtonText: 'Read now'
}
