import {connect} from "react-redux";

const modals = [
    /*{
        key:"buyCurrency",
        component: BuyModal
    },
    {
        key:'sellCurrency',
        component:SellModal
    }*/
]

const mapToState = (state) => {
    return {modal: state.modal}
}

export const ModalContainer = connect(mapToState)(({modal}) => {
    const {key, modalData} = modal
    return <>
        {modals.map(m => {
            if(m.key === key) return <m.component key={m.key} data={modalData}/>
            return null
        })}
    </>
})