import image from '../../../_assets/downloadable-content/guide_1.png'

export default {
    "theme_color": "rgb(94 179 236 / 67%)",
    "badge":"Guidebook",
    "title":"3 Steps to Create a Culture of Well-being",
    "description":"Making well-being a part of organizational culture is essential for both the betterment of employees' mental health and the profitability of companies. This guidebook includes main steps and practical tips for creating a culture of well-being in an organization.",
    "details_title":"In this guidebook",
    "details_list":"The importance of well-being in organizations across the world and the anticipated effects of a well-being strategy\nThree steps to build of your organization's own well-being strategy\nTips to identify the needs within your organization\nSuggestions to create a supportive environment to customize your well-being strategy\nKey performance indicators to track the results of and evaluate your strategic plan",
    "submit_button_text":"Download",
    "form_title":"Read the guidebook for free",
    "file": "3 Steps To Create Culture of Well-Being.pdf",
    image: image,
    image_width: 87,
    image_top: -11,
    image_left: 7,
    url: '/downloadable-content/3-steps-to-create-a-culture-of-well-being',
    listButtonText: 'Read now'
}