import image from '../../../_assets/downloadable-content/yu.png'

export default {
    "badge":"Webinar",
    "theme_color": "rgb(227 14 118 / 39%)",
    "title":"Protecting what matters: How Yulife's clients' and employees' improved their well-being with Meditopia",
    "description":"When we think about insurance companies, we imagine gray and cold offices. Fortunately for their clients and employees, YuLife isn’t that kind of company. Join us on this webinar to discover how in a revolutionary alliance with Meditopia, we are taking care of their physical and mental health through coaching and mindfulness resources.",
    "details_title":"In this webinar",
    "details_list":"How gamification supports people’s well-being at work\nWhy HR Stakeholders are searching for mental well-being solutions\nHow companies can support their teams’ mental well-being and overall performance",
    "submit_button_text":"Watch",
    "form_title":"Watch the webinar now",
    "page_title":"Protecting what matters: How Yulife's clients' and employees' improved their well-being with Meditopia",
    "image_width":100,
    "image_top":0,
    "image_left":0,
    "image":image,
    "redirect_uri": "https://youtu.be/7XysWBGW3bA",
    url: '/downloadable-content/protecting-what-matters-yulife',
    listButtonText: 'Watch now'
}
