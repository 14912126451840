import mfwbriefimage from '../../_assets/downloadable-content/mfw.png'
import mailchat from '../../_assets/downloadable-content/icons/mailchat.png'
import tickbox from '../../_assets/downloadable-content/icons/tickbox.png'
import listbox from '../../_assets/downloadable-content/icons/list.png'
import handwithstar from '../../_assets/downloadable-content/icons/handwithstar.png'
import brandIcons from '../../_assets/downloadable-content/brand-icons.png'

const iconsAndTexts = [
    {
        icon: mailchat,
        text: 'Dedicated mental health coach'
    },
    {
        icon: tickbox,
        text: 'Personalized mental health program'
    },
    {
        icon: listbox,
        text: 'User insights dashboard with unbiased data on the mental well-being of your team'
    },
    {
        icon: handwithstar,
        text: 'Continuous support to maximize employee engagement'
    }
]

const MeditopiaForWorkSection = ({title, paragraphs, note, iconOverwrite}) => {
    return <div className={'col-12 col-md-12 meditopia-for-work-section'}>
        <h4>Meditopia for Work</h4>
        <p className={'mfw-description'}>Daily mental health support for your team</p>
        <div className={'col-12 col-md-6 p-0 p-md-4'}>
            <img src={mfwbriefimage}/>
        </div>
        <div className={'col-12 col-md-6 mfw-right-side'}>
            {iconsAndTexts.map(it => <div key={`mfw-${it.text}`} className={'icons-text-list'}>
                <img src={it.icon}/>
                <p>{it.text}</p>
            </div>)}
            <button onClick={() => window.open('https://meditopia.com/en/forwork/')} className={'mfw-learn-more'}> Learn More </button>
        </div>
        <img src={brandIcons} className={'brand-icons'}/>
    </div>
}

export {MeditopiaForWorkSection}