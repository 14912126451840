import {injectIntl, IntlProvider} from "react-intl";
import {connect} from "react-redux";
import {setAvailableLanguages, setLocale} from "../_redux/actions/i18n/i18n.actions";
import {Input} from "reactstrap";

const context = require.context('../_lang', true, /.json$/);
const locales = {};
context.keys().forEach((key) => {
    const fileName = key.replace('./', '');
    const resource = require(`../_lang/${fileName}`);
    const namespace = fileName.replace('.json', '');
    locales[namespace] = JSON.parse(JSON.stringify(resource));
});

const mapToState = (state) => ({locale: state.i18n.locale, availableLanguages: state.i18n.availableLanguages})

setAvailableLanguages(Object.keys(locales))

const I18nProvider = connect(mapToState)(({children, locale}) => {
    return <IntlProvider
            locale={locale}
            messages={locales[locale]}
        >
        {children}
    </IntlProvider>
})

const LanguageSelect = injectIntl(connect(mapToState)(({intl, availableLanguages, locale}) => {
    return <Input type="select"
                  className={'language-select'}
                  value={locale} onChange={(e) => {setLocale(e.target.value)}}>
        {availableLanguages.map(o => <option value={o} key={`languageSelect-${o}`}>{intl.formatMessage({id: o, defaultMessage: o})}</option>)}
    </Input>
}))

export {
    I18nProvider,
    LanguageSelect
}